<div class="modal-header">
  <div>
    <h4 class="modal-title">
      {{ "plan.modify_modal.title" | translate }}
    </h4>
    <div class="label-container">
      <label class="sub-title">{{ employeeName }}</label>
    </div>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.close(false)"
  ></button>
</div>
<div class="modal-body">
  <form class="form" [formGroup]="planForm">
    <div class="form-row">
      <label>{{ "plan.modify_modal.project_name" | translate }}</label>
      <label class="text-end">{{ projectName }}</label>
    </div>
    <div class="form-row">
      <label for="from">{{ "plan.modify_modal.start" | translate }}</label>
      <div class="wrapper">
        <input formControlName="from" id="from" type="date" />
        <span
          *ngIf="
            planForm.get('from')?.hasError('required') &&
            planForm.get('from')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="to">{{ "plan.modify_modal.end" | translate }}</label>
      <div class="wrapper">
        <input formControlName="to" id="to" type="date" />
        <span
          *ngIf="
            planForm.get('to')?.hasError('required') &&
            planForm.get('to')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
        <span *ngIf="planForm.hasError('invalidDate')" class="form-error">
          {{ "plan.create_modal.error.to_earlier_than_from" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="percentage">
        {{ "plan.modify_modal.percentage" | translate }}
      </label>
      <div class="wrapper">
        <input
          type="range"
          min="0"
          max="100"
          step="5"
          formControlName="percentage"
          [value]="planForm.value.percentage"
        />
        <input
          id="percentage"
          type="number"
          min="0"
          max="100"
          class="percentage-number-input"
          formControlName="percentage"
          [value]="planForm.value.percentage"
          (blur)="validatePercentageField()"
        />
        <span
          *ngIf="
            planForm.get('percentage')?.hasError('min') &&
            planForm.get('percentage')?.touched
          "
          class="form-error"
        >
          {{ "form.error.percentage_min_value" | translate }}
        </span>
        <span
          *ngIf="
            planForm.get('percentage')?.hasError('required') &&
            planForm.get('percentage')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="description">
        {{ "plan.modify_modal.description" | translate }}
      </label>
      <div class="w-50">
        <textarea
          id="description"
          class="px-2"
          rows="4"
          placeholder="{{ 'plan.modify_modal.placeholder' | translate }}"
          formControlName="description"
        ></textarea>
      </div>
    </div>
    <div class="form-row">
      <label>{{ "plan.modify_modal.competency" | translate }}</label>
      <div class="w-50">
        <app-competency-pill-container
          [competencies]="competencies"
          [allCompetencies]="allCompetencies"
          [employeeCompetencies]="employeeCompetencies"
          [commands]="commands"
          [isAddPillButtonActive]="true"
          (newCompetencyEvent)="checkAndAddNewCompetency($event)"
        ></app-competency-pill-container>
      </div>
    </div>
  </form>
</div>
<div *ngIf="error" class="error">
  <ngb-alert class="alert" type="danger" (closed)="closeAlert()">
    {{ errorMessage }}
  </ngb-alert>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary secondary-btn"
    (click)="activeModal.close(false)"
  >
    {{ "plan.modify_modal.cancel" | translate }}
  </button>
  <button
    type="button"
    class="btn primary-btn"
    [disabled]="isLoading"
    (click)="savePlan()"
  >
    {{ "plan.modify_modal.save" | translate }}
  </button>
</div>
