<div class="d-flex flex-column justify-content-between h-100 position-relative">
  <div class="d-flex flex-column main-container" style="min-height: 0">
    <div class="d-flex flex-row justify-content-between table-actions">
      <div class="flex-grow-1 d-flex flex-row align-items-center search">
        <fa-icon
          class="ms-auto remove-icon"
          [icon]="['fas', 'magnifying-glass']"
        ></fa-icon>
        <input
          class="form-control form-control-sm flex-grow-1"
          type="text"
          placeholder="{{ 'home.looking_for_project.search' | translate }}"
          [formControl]="searchText"
        />
        <div class="btn-group btn-group-sm ms-2" btnRadioGroup>
          <label
            class="btn btn-outline-primary display-mode-button"
            [class.active]="displayedLookingForProjects === 'active'"
          >
            <input
              type="radio"
              class="btn-check"
              name="employeeStatus"
              autocomplete="off"
              [(ngModel)]="displayedLookingForProjects"
              value="active"
              (change)="toggleDisplayMode()"
            />
            {{ "home.looking_for_project.display_mode.active" | translate }}
          </label>
          <label
            class="btn btn-outline-primary display-mode-button"
            [class.active]="displayedLookingForProjects === 'all'"
          >
            <input
              type="radio"
              class="btn-check"
              name="employeeStatus"
              autocomplete="off"
              [(ngModel)]="displayedLookingForProjects"
              value="all"
              (change)="toggleDisplayMode()"
            />
            {{ "home.looking_for_project.display_mode.all" | translate }}
          </label>
        </div>
      </div>
      <button
        class="btn primary-btn ms-2"
        (click)="openAddEmployeeDialog()"
        [disabled]="!dataLoaded"
      >
        {{ "home.looking_for_project.add" | translate }}
      </button>
    </div>
    <div
      *ngIf="
        !lookingForProjectEntities.length && !searchText.value?.trim()?.length
      "
      class="image-container"
    >
      <img src="..\..\assets\img\squirrel_sleep.png" />
    </div>
    <div
      *ngIf="
        lookingForProjectEntities.length || searchText.value?.trim()?.length
      "
      class="table-container"
    >
      <table
        class="table table-light table-hover table-striped table-bordered align-middle scrollable-table"
      >
        <thead>
          <tr>
            <th
              scope="col"
              sortable="employee.name"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "home.looking_for_project.name" | translate }}
            </th>
            <th
              scope="col"
              sortable="assignee.name"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "home.looking_for_project.assignee" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{ "home.looking_for_project.status.label" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{ "home.looking_for_project.comment" | translate }}
            </th>
            <th
              scope="col"
              sortable="freeFrom"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "home.looking_for_project.free_from" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{ "home.looking_for_project.seniority.title" | translate }}
            </th>
            <th
              scope="col"
              sortable="employee.position.type"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "home.looking_for_project.position" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{ "home.looking_for_project.cv" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              <span
                ngbTooltip="{{
                  'home.looking_for_project.competencies_tooltip' | translate
                }}"
                placement="bottom"
                container="body"
                [positionTarget]="target"
              >
                {{ "home.looking_for_project.competencies" | translate }}
                <span #target
                  ><fa-icon [icon]="['fas', 'circle-info']" size="lg"></fa-icon
                ></span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            app-tr-looking-for-project
            *ngFor="let lookingForProjectEntity of lookingForProjectEntities"
            [lookingForProject]="lookingForProjectEntity"
            [users]="users"
            [searchTerm]="searchText.value?.trim()"
            [search]="search"
            [allCompetencies]="competencies"
            (lookingForProjectSaved)="refreshLookingForProjects()"
            (click)="
              selectRow(
                lookingForProjectEntity.id!,
                lookingForProjectEntity.employeeId
              )
            "
            [ngClass]="{
              selected: selectedRows.has(lookingForProjectEntity.id!)
            }"
          ></tr>
        </tbody>
      </table>
    </div>
    <div
      *ngIf="
        lookingForProjectEntities.length || searchText.value?.trim()?.length
      "
      class="d-flex justify-content-between align-items-top p-2"
    >
      <ngb-pagination
        [collectionSize]="collectionSize"
        [(page)]="page"
        [pageSize]="pageSize"
        (pageChange)="refreshLookingForProjects()"
        class="pages"
      >
      </ngb-pagination>
      <div class="information">
        {{ lookingForProjectEntities.length }} /
        {{ collectionSize }}
      </div>
    </div>
  </div>
  <app-basket-floating-button
    [employeeIds]="selectedEmployees"
  ></app-basket-floating-button>
</div>
