<div class="modal-header">
  <h4 class="modal-title">
    {{ "home.looking_for_resources.create_modal.title" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.close(false)"
  ></button>
</div>
<div class="modal-body">
  <form class="form" [formGroup]="lookingForResourceForm">
    <div class="form-row">
      <label for="project">
        {{ "home.looking_for_resources.create_modal.project" | translate }}
      </label>
      <div class="wrapper">
        <input
          id="project"
          type="text"
          formControlName="project"
          [ngbTypeahead]="searchProject"
          [resultFormatter]="formatter"
          [inputFormatter]="formatter"
          [editable]="false"
          (focus)="projectFocus$.next($any($event).target.value)"
          (click)="projectFocus$.next($any($event).target.value)"
          #employeeInstance="ngbTypeahead"
        />
        <span
          *ngIf="
            lookingForResourceForm.get('project')?.hasError('required') &&
            lookingForResourceForm.get('project')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="assignee">
        {{ "home.looking_for_resources.create_modal.assignee" | translate }}
      </label>
      <div class="wrapper">
        <input
          id="assignee"
          type="text"
          formControlName="assignee"
          [ngbTypeahead]="searchAssignee"
          [resultFormatter]="formatter"
          [inputFormatter]="formatter"
          [editable]="false"
          (focus)="assigneeFocus$.next($any($event).target.value)"
          (click)="assigneeClick$.next($any($event).target.value)"
          #assigneeInstance="ngbTypeahead"
        />
        <span
          *ngIf="
            lookingForResourceForm.get('assignee')?.hasError('required') &&
            lookingForResourceForm.get('assignee')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="requestStart">
        {{ "home.looking_for_resources.create_modal.start" | translate }}
      </label>
      <div class="wrapper">
        <input id="requestStart" formControlName="requestStart" type="date" />
      </div>
    </div>
    <div class="form-row">
      <label for="requestEnd">
        {{ "home.looking_for_resources.create_modal.end" | translate }}
      </label>
      <div class="wrapper">
        <input id="requestEnd" formControlName="requestEnd" type="date" />
      </div>
    </div>
    <div class="form-row">
      <label for="ftre">
        {{ "home.looking_for_resources.create_modal.fte" | translate }}
      </label>
      <div class="wrapper">
        <input
          id="ftre"
          formControlName="fte"
          type="number"
          step="0.25"
          min="0"
        />
        <span
          *ngIf="
            lookingForResourceForm.get('fte')?.hasError('required') &&
            lookingForResourceForm.get('fte')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
  </form>
</div>
<div *ngIf="error" class="error">
  <ngb-alert class="alert" type="danger" (closed)="closeAlert()">
    {{ errorMessage }}
  </ngb-alert>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary secondary-btn"
    (click)="activeModal.close(false)"
  >
    {{ "home.looking_for_resources.create_modal.cancel" | translate }}
  </button>
  <button type="button" class="btn primary-btn" (click)="saveNewProject()">
    {{ "home.looking_for_resources.create_modal.save" | translate }}
  </button>
</div>
