import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import {
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  switchMap,
  tap,
} from "rxjs";
import {
  CompetencyDTO,
  CompetencyService,
  EmployeeSelectDTO,
  EmployeeService,
  LookingForProjectDTO,
  LookingForProjectService,
  UserSelectDTO,
  UserService,
} from "src/shared/api/generated";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/shared/directives/ngbd-sortable-header.directive";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { CreateLookingForProjectComponent } from "../create-looking-for-project/create-looking-for-project.component";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-table-looking-for-project",
  templateUrl: "./table-looking-for-project.component.html",
  styleUrls: ["./table-looking-for-project.component.scss"],
})
export class TableLookingForProjectComponent implements OnInit {
  lookingForProjectEntities: LookingForProjectDTO[] = [];
  users: UserSelectDTO[] = [];
  employees: EmployeeSelectDTO[] = [];
  page = 1;
  pageSize = 15;
  collectionSize = 0;
  sorting: SortEvent = { column: "employee.name", direction: "asc" };
  selectedRows: Map<number, number> = new Map();
  selectedEmployees: number[] = [];
  searchText = new FormControl("");
  search: boolean = false;
  competencies: Map<number, string> = new Map();

  dataLoaded: boolean = false;

  displayedLookingForProjects = "active";

  constructor(
    private spinnerService: NgxSpinnerService,
    private lookingForProjectService: LookingForProjectService,
    private userService: UserService,
    private competencyService: CompetencyService,
    private competencyManagerService: CompetencyManagerService,
    private employeeService: EmployeeService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.initialLoad();
    this.searchLookingForProjects();
  }

  getLookingForProjectEntities(searchTerm: string = "") {
    this.competencyManagerService.clearBothCompetencies();
    if (this.displayedLookingForProjects === "active") {
      return this.lookingForProjectService.getAllActiveLookingForProjects(
        searchTerm.trim(),
        this.pageSize,
        this.page - 1,
        this.sorting.column,
        this.sorting.direction
      );
    }
    return this.lookingForProjectService.getAllLookingForProjects(
      searchTerm.trim(),
      this.pageSize,
      this.page - 1,
      this.sorting.column,
      this.sorting.direction
    );
  }

  getUsers() {
    return this.userService.getAllUsers();
  }

  getEmployees() {
    return this.employeeService.getAllEmployeeSelectDtos();
  }

  getAllCompetencies() {
    return this.competencyService.getAllCompetencies();
  }

  initialLoad() {
    forkJoin([
      this.getUsers(),
      this.getEmployees(),
      this.getAllCompetencies(),
      this.getLookingForProjectEntities(),
    ])
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe(
        ([users, employees, competencies, lookingForProjectEntities]) => {
          this.users = users;
          this.employees = employees;
          competencies.forEach((competency: CompetencyDTO) => {
            this.competencies.set(competency.id!, competency.name);
          });
          this.clearRowSelection();
          this.lookingForProjectEntities =
            lookingForProjectEntities.content ?? [];
          this.collectionSize = lookingForProjectEntities.totalElements ?? 0;
          this.dataLoaded = true;
        }
      );
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader> =
    new QueryList();

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.sorting = { column: column, direction: direction };
    this.refreshLookingForProjects();
  }

  searchLookingForProjects() {
    this.searchText.valueChanges
      .pipe(
        tap(() => (this.search = false)),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.competencyManagerService.clearEmployeeCompetency();
        }),
        switchMap((text) =>
          this.getLookingForProjectEntities(text ?? "").pipe(
            showLoadingSpinner(this.spinnerService)
          )
        )
      )
      .subscribe((lookingForProjectPage) => {
        this.clearRowSelection();
        this.lookingForProjectEntities = lookingForProjectPage.content ?? [];
        this.collectionSize = lookingForProjectPage.totalElements ?? 0;
        this.search = true;
      });
  }

  toggleDisplayMode() {
    this.page = 1;
    this.refreshLookingForProjects();
  }

  refreshLookingForProjects() {
    this.getLookingForProjectEntities(this.searchText.value ?? "")
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((lookingForProjectPage) => {
        this.clearRowSelection();
        this.lookingForProjectEntities = lookingForProjectPage.content ?? [];
        this.collectionSize = lookingForProjectPage.totalElements ?? 0;
      });
  }

  openAddEmployeeDialog() {
    const modalRef = this.modalService.open(CreateLookingForProjectComponent);
    modalRef.componentInstance.employees = this.employees;
    modalRef.componentInstance.users = this.users;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.refreshLookingForProjects();
        }
      },
      () => {}
    );
  }

  selectRow(id: number, employeeId: number) {
    if (this.selectedRows.has(id)) {
      this.selectedRows.delete(id);
      this.selectedEmployees = Array.from(this.selectedRows.values());
    } else {
      this.selectedRows.set(id, employeeId);
      this.selectedEmployees = Array.from(this.selectedRows.values());
    }
  }

  clearRowSelection() {
    this.selectedRows.clear();
    this.selectedEmployees = [];
  }
}
