<div class="modal-root">
  <div class="modal-header">
    <div>
      <h4 class="modal-title">
        {{ "plan.create_modal.title" | translate }}
      </h4>
      <div class="label-container">
        <label class="sub-title text-overflow-dotted">{{ projectName }}</label>
      </div>
    </div>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="activeModal.close(planListModified)"
    ></button>
  </div>
  <div class="main-container">
    <div
      class="member-container"
      *ngIf="projectMembers.length != 0 && !addMode"
    >
      <div class="member-list" *ngIf="!addMode">
        <div ngbAccordion class="accordion-flush pb-3" [closeOthers]="true">
          <div
            ngbAccordionItem
            *ngFor="let memberPlan of projectMembers"
            (hide)="cancelEditing()"
          >
            <h2 ngbAccordionHeader class="w-100">
              <button ngbAccordionButton class="py-1 w-100">
                <div
                  class="accordion-header d-flex flex-row justify-content-between align-items-center pe-2"
                >
                  <span class="member-name pe-1 py-1"
                    ><b>{{ memberPlan.employeeName }}</b> </span
                  ><span class="member-position pe-1 py-1">
                    {{ memberPlan.employeePosition }}</span
                  >
                  <ngb-progressbar
                    class="progressbar"
                    [showValue]="true"
                    type="primary"
                    [value]="memberPlan.percentage!"
                  />
                </div>
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <div class="d-flex flex-column">
                    <div class="pb-2">
                      <div>
                        <label class="task-title-label pe-2">{{
                          "project.members_modal.tasks" | translate
                        }}</label>
                        <span
                          *ngIf="editedMemberPlan.planId !== memberPlan.planId"
                        >
                          <fa-icon
                            class="edit-icon"
                            [icon]="['fas', 'pen']"
                            (click)="setEditedMemberPlan(memberPlan)"
                          ></fa-icon>
                        </span>
                        <span
                          *ngIf="editedMemberPlan.planId === memberPlan.planId"
                        >
                          <fa-icon
                            class="save-icon"
                            [icon]="['fas', 'check']"
                            (click)="saveMemberPlan()"
                          ></fa-icon>
                          <fa-icon
                            class="cancel-edit-icon ps-2"
                            [icon]="['fas', 'xmark']"
                            (click)="cancelEditing()"
                          ></fa-icon>
                        </span>
                      </div>

                      <div
                        class="ms-2 description-label"
                        *ngIf="editedMemberPlan.planId !== memberPlan.planId"
                      >
                        {{ memberPlan.description }}
                      </div>
                      <textarea
                        class="px-2"
                        rows="4"
                        placeholder="{{
                          'project.members_modal.placeholder' | translate
                        }}"
                        *ngIf="editedMemberPlan.planId === memberPlan.planId"
                        [(ngModel)]="editedMemberPlan.description"
                        (keydown.enter)="saveMemberPlan()"
                        (keydown.escape)="cancelEditing()"
                      ></textarea>
                    </div>
                    <label class="tech-title-label">{{
                      "project.members_modal.technologies" | translate
                    }}</label>
                    <div
                      *ngFor="
                        let competency of memberPlan.linkedCompetencies!
                          | keyvalue
                      "
                    >
                      <span class="ms-2 pe-2">{{ competency.value }}</span>
                      <span>{{
                        allCompetencies.get(convertToNumber(competency.key))
                      }}</span>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="empty-member-container"
      *ngIf="projectMembers.length == 0 && !addMode"
    >
      <label class="text-center">
        {{ "project.members_modal.empty_members" | translate }}
      </label>
    </div>
    <div class="add-member-form-container" *ngIf="addMode">
      <app-add-member
        (memberAddCancelled)="onMemberAddCancelled()"
        (memberAddConfirmed)="onMemberAddConfirmed()"
        [selectedProjectId]="projectId"
      >
      </app-add-member>
    </div>
    <div class="d-flex flex-row justify-content-center" *ngIf="!addMode">
      <button
        type="button"
        class="btn btn-primary add-member-button"
        (click)="openAddMemberDialog()"
      >
        <fa-icon
          [icon]="['fas', 'plus']"
          (click)="openAddMemberDialog()"
        ></fa-icon>
        {{ "project.members_modal.add" | translate }}
      </button>
    </div>
  </div>
</div>
