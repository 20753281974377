<div class="modal-header">
  <h4 *ngIf="createMode" class="modal-title">
    {{ "competency.competency_add_modal.create_competency" | translate }}
  </h4>
  <h4 *ngIf="!createMode" class="modal-title">
    {{ "competency.competency_add_modal.update_competency" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.close(false)"
  ></button>
</div>
<div class="modal-body">
  <form class="form" [formGroup]="competencyForm">
    <div class="form-row">
      <label for="competency-name-input">
        {{ "competency.competency_add_modal.competency_name" | translate }}:
      </label>
      <div class="wrapper w-100">
        <input
          id="competency-name-input"
          type="text"
          formControlName="name"
          class="w-50"
        />
        <span
          *ngIf="
            competencyForm.get('name')?.hasError('required') &&
            competencyForm.get('name')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="competency-group-select">
        {{ "competency.competency_add_modal.competency_type" | translate }}:
      </label>
      <div class="wrapper w-100">
        <select
          id="competency-group-select"
          formControlName="competencyGroupId"
          class="px-1 w-50"
        >
          <option *ngFor="let type of competencyGroups" value="{{ type.id }}">
            {{ type.name }}
          </option>
        </select>
      </div>
    </div>
  </form>
</div>
<div *ngIf="error" class="error">
  <ngb-alert class="alert" type="danger" (closed)="closeAlert()">
    {{ errorMessage }}
  </ngb-alert>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary secondary-btn"
    (click)="activeModal.close(false)"
  >
    {{ "competency.competency_add_modal.cancel" | translate }}
  </button>
  <button
    type="button"
    class="btn primary-btn"
    (click)="saveCompetency()"
  >
    {{ "competency.competency_add_modal.save" | translate }}
  </button>
</div>
