import { Component, Input, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { debounceTime, distinctUntilChanged, filter, map, merge, Observable, OperatorFunction, Subject } from "rxjs";
import { EmployeeSelectDTO, LookingForProjectCreateDTO, LookingForProjectService, UserSelectDTO } from "src/shared/api/generated";

@Component({
  selector: "app-create-looking-for-project",
  templateUrl: "./create-looking-for-project.component.html",
  styleUrls: ["./create-looking-for-project.component.scss"],
})
export class CreateLookingForProjectComponent {
  @Input() employees: EmployeeSelectDTO[] = [];
  @Input() users: UserSelectDTO[] = [];

  lookingForProjectForm: FormGroup = new FormGroup({
    employee: new FormControl<EmployeeSelectDTO | null>(null, Validators.required),
    assignee: new FormControl<UserSelectDTO | null>(null, Validators.required),
    freeFrom: new FormControl<string>("", Validators.required)
  });

  error: boolean = false;
  errorMessage: string = "";

  @ViewChild("employeeInstance", { static: true })
  employeeInstance: NgbTypeahead = {} as NgbTypeahead;
  @ViewChild("assigneeInstance", { static: true })

  assigneeInstance: NgbTypeahead = {} as NgbTypeahead;

  employeeFocus$ = new Subject<string>();
  assigneeFocus$ = new Subject<string>();
  employeeClick$ = new Subject<string>();
  assigneeClick$ = new Subject<string>();

  constructor(
    public activeModal: NgbActiveModal,
    private lookingForProjectService: LookingForProjectService,
    private translate: TranslateService
  ) {}

  saveNewEmployee() {
    if (this.isDisabled()) {
      this.lookingForProjectForm.markAllAsTouched();
      return;
    }

    this.lookingForProjectService
      .createLookingForProject({
        status: "WAITING",
        employeeId: this.employee.id!,
        assigneeId: this.assignee.id!,
        freeFrom: this.freeFrom,
      })
      .subscribe({
        next: (_) => {
          this.activeModal.close(true);
        },
        error: (error) => {
          if (
            error.error.message ===
            "There is already an active Looking For Project for the given employee"
          )
            this.errorMessage = this.translate.instant(
              "home.looking_for_project.create_modal.error.already_has_active_entry"
            );
          this.error = true;
        },
      });
  }

  isDisabled(): boolean {
    return (
      !this.assignee || this.assignee.id < 0 ||
      !this.employee || this.employee?.id! < 0 ||
      !this.freeFrom
    );
  }

  closeAlert() {
    this.error = false;
  }

  searchEmployee: OperatorFunction<string, EmployeeSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.employeeClick$.pipe(
      filter(() => !this.employeeInstance.isPopupOpen())
    );
    const inputFocus$ = this.employeeFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? []
          : this.employees.filter((v) => new RegExp(term, "miu").test(v.name!))
        ).slice(0, 10)
      )
    );
  };

  searchAssignee: OperatorFunction<string, UserSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.assigneeClick$.pipe(
      filter(() => !this.assigneeInstance.isPopupOpen())
    );
    const inputFocus$ = this.assigneeFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? this.users
          : this.users.filter((v) => new RegExp(term, "miu").test(v.name!))
        )
          .sort((a, b) => a.name!.localeCompare(b.name!))
          .slice(0, 10)
      )
    );
  };

  formatter = (x: EmployeeSelectDTO | UserSelectDTO) => x.name!;

  get employee() {
    return this.lookingForProjectForm.get('employee')?.value;
  }

  get assignee() {
    return this.lookingForProjectForm.get('assignee')?.value;
  }

  get freeFrom() {
    return this.lookingForProjectForm.get('freeFrom')?.value;
  }
}
