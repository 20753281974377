import { DATE_PIPE_DEFAULT_OPTIONS } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  Observable,
  OperatorFunction,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  merge,
} from "rxjs";
import {
  CompetencyDTO,
  CompetencyService,
  EmployeeCompetencyService,
  PlanCreateDTO,
  PlanService,
  ProjectSelectDTO,
  ProjectService,
} from "src/shared/api/generated";
import { CompetencyPillCommand } from "src/shared/models/competency-pill-command.model";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";
import { dateValidator } from "src/shared/validators/date.validator";

@Component({
  selector: "app-create-plan",
  templateUrl: "./create-plan.component.html",
  styleUrls: ["./create-plan.component.scss"],
  providers: [
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: "yyyy/MM/dd" },
    },
  ],
})
export class CreatePlanComponent implements OnInit {
  @Input() employeeId: number = -1;
  @Input() employeeName: string = "";

  @ViewChild("projectInstance", { static: true })
  projectInstance: NgbTypeahead = {} as NgbTypeahead;

  employeeCompetencies: CompetencyDTO[] = [];
  projects: ProjectSelectDTO[] = [];

  planForm: FormGroup = new FormGroup({
    selectedProject: new FormControl<ProjectSelectDTO | null>({value: null, disabled: true }, Validators.required),
    from: new FormControl<string>("", Validators.required),
    to: new FormControl<string>("", Validators.required),
    description: new FormControl<string | null>(null),
    percentage: new FormControl<number>(100, [Validators.required, Validators.min(1), Validators.max(100)])
  }, [dateValidator]);

  error: boolean = false;
  errorMessage: string = "";

  projectFocus$ = new Subject<string>();
  projectClick$ = new Subject<string>();

  competencies: CompetencyDTO[] = [];
  allCompetencies: CompetencyDTO[] = [];

  isLoading: boolean = false;

  deleteCompetencyFromList = (selectedCompetency: CompetencyDTO) => {
    this.competencies = this.competencies.filter(
      (competency) => competency.id !== selectedCompetency.id
    );
  };
  commands: CompetencyPillCommand[] = [
    {
      icon: "trash",
      function: this.deleteCompetencyFromList,
    },
  ];

  searchProject: OperatorFunction<string, ProjectSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.projectClick$.pipe(
      filter(() => !this.projectInstance.isPopupOpen())
    );
    const inputFocus$ = this.projectFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? []
          : this.projects.filter((v) => new RegExp(term, "miu").test(v.name!))
        ).slice(0, 10)
      )
    );
  };
  inputFormatter = (x: ProjectSelectDTO) => x.name!;

  constructor(
    public activeModal: NgbActiveModal,
    private planService: PlanService,
    private competencyService: CompetencyService,
    private employeeCompetencyService: EmployeeCompetencyService,
    private projectService: ProjectService,
    private spinnerService: NgxSpinnerService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    forkJoin([
      this.projectService.getAllProjectSelectDtos(),
      this.competencyService.getAllCompetenciesByEmployeeId(this.employeeId),
    ])
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe(([allProjects, employeeCompetencies]) => {
        this.projects = allProjects;
        this.employeeCompetencies = employeeCompetencies;
        this.planForm.get("selectedProject")?.enable();
      });
  }

  fetchCompetencies(selectItem: any) {
    this.competencyService
      .getAllCompetenciesByProjectId(selectItem.item.id)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((allCompetencies) => (this.allCompetencies = allCompetencies));
    this.competencies = [];
  }

  isDisabled(): boolean {
    return (
      this.selectedProject?.id! < 0 ||
      this.planForm.invalid
    );
  }

  closeAlert() {
    this.error = false;
  }

  saveNewPlan() {
    if (this.isDisabled()) {
      this.planForm.markAllAsTouched();
      return;
    }
    const { from, to, percentage, description } = this.planForm.value;
    const newPlan = {
      from,
      to,
      percentage,
      description,
      employeeId: this.employeeId,
      projectId: this.planForm.value.selectedProject.id!,
      competencyIdList: this.competencies.map(
        (competency) => competency.id!
      )
    } as PlanCreateDTO;
    this.planService.createPlan(newPlan).subscribe({
      next: (_) => {
        this.activeModal.close(true);
      },
      error: (error) => {
        if (
          error.error.message ===
          "Plan overlaps with another plan for the given employee and project"
        )
          this.errorMessage = this.translate.instant(
            "plan.create_modal.error.plans_overlap_on_employee"
          );
        this.error = true;
      },
    });
  }

  checkAndAddNewCompetency({ competency, level }: any): void {
    this.isLoading = true;
    if (level) {
      this.saveEmployeeCompetency(competency, level);
    } else {
      this.addNewCompetency(competency);
    }
  }

  saveEmployeeCompetency(competency: CompetencyDTO, level: number): void {
    this.employeeCompetencyService
      .createEmployeeCompetency({
        employeeId: this.employeeId,
        competencyId: competency.id!,
        level: level,
      })
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: () => {
          this.employeeCompetencies.push(competency);
          this.addNewCompetency(competency);
        },
      });
  }

  addNewCompetency(competency: CompetencyDTO): void {
    this.competencies.push(competency);
    this.isLoading = false;
  }

  validatePercentageField(): void {
    const control = this.planForm.get("percentage");
    if (!control || !control.value) return;

    if (control.value > 100) {
      control.setValue(100);
    } else if (control.value < 1) {
      control.setValue(1);
    }
  }

  get selectedProject() {
    return this.planForm.get("selectedProject")?.value;
  }
}
