<div class="main-container">
  <label class="title-label">{{
    "home.comment_modal.title" | translate
  }}</label>
  <label class="name-label text-overflow-dotted">{{ entityName }}</label>

  <div class="comment-container">
    <div class="comment-header">
      <label class="title-label">{{
        "home.comment_modal.comment" | translate
      }}</label>
      <fa-icon
        *ngIf="lookingForEntity.comment"
        class="edit-icon"
        [icon]="['fas', 'pen']"
        (click)="setEditMode()"
      ></fa-icon>
      <fa-icon
        *ngIf="!lookingForEntity.comment"
        class="edit-icon"
        [icon]="['fas', 'circle-plus']"
        (click)="setEditMode()"
      ></fa-icon>
    </div>
    <textarea
      *ngIf="editMode"
      cols="30"
      rows="5"
      maxlength="255"
      placeholder="{{ 'home.comment_modal.max_length' | translate }}"
      [(ngModel)]="editLookingForEntity.comment"
    ></textarea>
    <p *ngIf="!editMode" class="comment">
      {{ lookingForEntity.comment }}
    </p>
  </div>
  <div class="modal-actions">
    <button
      type="button"
      class="btn btn-primary secondary-btn"
      (click)="activeModal.close(false)"
    >
      {{ "home.comment_modal.cancel" | translate }}
    </button>
    <button
      *ngIf="editMode && mode === 'looking-for-resource'"
      [disabled]="isDisabled()"
      type="button"
      class="btn primary-btn"
      (click)="saveLookingForResource()"
    >
      {{ "home.comment_modal.save" | translate }}
    </button>
    <button
      *ngIf="editMode && mode === 'looking-for-project'"
      [disabled]="isDisabled()"
      type="button"
      class="btn primary-btn"
      (click)="saveLookingForProject()"
    >
      {{ "home.comment_modal.save" | translate }}
    </button>
  </div>
</div>
