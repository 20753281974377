<div class="d-flex flex-column justify-content-between h-100">
  <div class="d-flex flex-column main-container" style="min-height: 0">
    <div class="d-flex flex-row justify-content-between table-actions">
      <div class="flex-grow-1 d-flex flex-row align-items-center search">
        <fa-icon
          class="ms-auto remove-icon"
          [icon]="['fas', 'magnifying-glass']"
        ></fa-icon>
        <input
          data-testid="search-table"
          class="form-control form-control-sm flex-grow-1"
          type="text"
          placeholder="{{ 'project.search' | translate }}"
          [formControl]="searchText"
        />
        <div class="btn-group btn-group-sm ms-2" btnRadioGroup>
          <label
            class="btn btn-outline-primary display-mode-button"
            [class.active]="displayedProjects === 'active'"
          >
            <input
              type="radio"
              class="btn-check"
              name="employeeStatus"
              autocomplete="off"
              [(ngModel)]="displayedProjects"
              value="active"
              [disabled]="createProjectMode"
              (change)="toggleDisplayMode()"
            />
            {{ "project.display_mode.active" | translate }}
          </label>
          <label
            class="btn btn-outline-primary display-mode-button"
            [class.active]="displayedProjects === 'all'"
          >
            <input
              type="radio"
              class="btn-check"
              name="employeeStatus"
              autocomplete="off"
              [(ngModel)]="displayedProjects"
              value="all"
              [disabled]="createProjectMode"
              (change)="toggleDisplayMode()"
            />
            {{ "project.display_mode.all" | translate }}
          </label>
        </div>
      </div>
      <div>
        <button
          *ngIf="!createProjectMode && !cloneProjectMode"
          class="btn primary-btn ms-2"
          (click)="addEmptyProject()"
        >
          {{ "project.add" | translate }}
        </button>
        <button
          *ngIf="createProjectMode"
          class="btn secondary-btn ms-2"
          (click)="cancelCreateProject()"
        >
          {{ "project.cancel" | translate }}
        </button>
        <button
          *ngIf="createProjectMode"
          class="btn primary-btn ms-2"
          [disabled]="isCreateDisabled()"
          (click)="createProject()"
        >
          {{ "project.save" | translate }}
        </button>
        <button
          *ngIf="cloneProjectMode"
          class="btn primary-btn ms-2"
          (click)="saveClonedProject()"
        >
          {{ "project.save" | translate }}
        </button>
        <button
          *ngIf="cloneProjectMode"
          class="btn primary-btn ms-2"
          (click)="cancelCloneProject()"
        >
          {{ "project.cancel" | translate }}
        </button>
      </div>
    </div>
    <div
      *ngIf="!projects.length && !searchText.value?.trim()?.length"
      class="image-container"
    >
      <img src="..\..\assets\img\squirrel_sleep.png" />
    </div>
    <div
      *ngIf="projects.length || searchText.value?.trim()?.length"
      class="table-container"
    >
      <table
        class="table table-light table-hover table-striped table-bordered align-middle scrollable-table"
      >
        <thead>
          <tr>
            <th
              scope="col"
              sortable="name"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "project.name" | translate }}
            </th>
            <th scope="col" class="table-header text-center alternative-name">
              {{ "project.alternative_name" | translate }}
            </th>
            <th scope="col" class="table-header text-center description">
              {{ "project.description" | translate }}
            </th>
            <th
              scope="col"
              sortable="start"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "project.start" | translate }}
            </th>
            <th
              scope="col"
              sortable="end"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "project.end" | translate }}
            </th>
            <th
              scope="col"
              sortable="internal"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "project.internal" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{ "project.state.label" | translate }}
            </th>
            <th
              scope="col"
              sortable="fte"
              (sort)="onSort($event)"
              class="table-header text-center fte"
            >
              {{ "project.fte" | translate }}
            </th>
            <th scope="col" class="table-header text-center competencies">
              <span
                ngbTooltip="{{ 'project.competencies_tooltip' | translate }}"
                placement="bottom"
                container="body"
                [positionTarget]="target"
              >
                {{ "project.competencies" | translate }}
                <span #target
                  ><fa-icon [icon]="['fas', 'circle-info']" size="lg"></fa-icon
                ></span>
              </span>
            </th>
            <th scope="col" class="table-header text-center">
              {{ "project.members" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            app-tr-project
            [cdkContextMenuTriggerFor]="contextMenu"
            (cdkContextMenuOpened)="highlightRow(project.id!)"
            (cdkContextMenuClosed)="removeHighlightFromRow()"
            [cdkContextMenuDisabled]="createProjectMode || cloneProjectMode"
            *ngFor="let project of projects.slice(0, pageSize)"
            [project]="project"
            [allCompetencies]="competencies"
            [createMode]="createProjectMode"
            [cloneMode]="cloneProjectMode"
            [searchTerm]="searchText.value?.trim()"
            [search]="search"
            (projectSaved)="saveProject($event)"
            (membersModified)="refreshProjects()"
            (click)="selectRow(project.id!)"
            [ngClass]="{
              selected: selectedRows.includes(project.id!),
              'context-menu-open': contextMenuOpenedFor === project.id
            }"
          >
            <ng-template #contextMenu>
              <div class="context-menu shadow rounded-3" cdkMenu>
                <button
                  class="context-menu-item"
                  (click)="cloneProject(project.id!)"
                  cdkMenuItem
                >
                  {{ "project.clone" | translate }}
                </button>
                <button
                  *ngIf="isAdmin"
                  class="context-menu-item"
                  (click)="openDeleteConfirmationModal(project.id!)"
                  cdkMenuItem
                >
                  {{ "project.delete" | translate }}
                </button>
              </div>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      *ngIf="projects.length || searchText.value?.trim()?.length"
      class="d-flex justify-content-between align-items-top p-2"
    >
      <ngb-pagination
        [collectionSize]="collectionSize"
        [(page)]="page"
        [pageSize]="pageSize"
        (pageChange)="refreshProjects()"
        class="pages"
      >
      </ngb-pagination>
      <div class="information">
        {{ projects.slice(0, pageSize).length }} /
        {{ collectionSize }}
      </div>
    </div>
  </div>
</div>
