<div class="modal-header">
  <h4 class="modal-title">
    {{ "home.looking_for_project.create_modal.title" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.close(false)"
  ></button>
</div>
<div class="modal-body">
  <form class="form" [formGroup]="lookingForProjectForm">
    <div class="form-row">
      <label for="employee">
        {{ "home.looking_for_project.create_modal.employee" | translate }}
      </label>
      <div class="wrapper">
        <input
          id="employee"
          type="text"
          formControlName="employee"
          [ngbTypeahead]="searchEmployee"
          [resultFormatter]="formatter"
          [inputFormatter]="formatter"
          [editable]="false"
          (focus)="employeeFocus$.next($any($event).target.value)"
          (click)="employeeClick$.next($any($event).target.value)"
          #employeeInstance="ngbTypeahead"
        />
        <span
          *ngIf="
            lookingForProjectForm.get('employee')?.hasError('required') &&
            lookingForProjectForm.get('employee')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="assignee">
        {{ "home.looking_for_project.create_modal.assignee" | translate }}
      </label>
      <div class="wrapper">
        <input
          id="assignee"
          type="text"
          formControlName="assignee"
          [ngbTypeahead]="searchAssignee"
          [resultFormatter]="formatter"
          [inputFormatter]="formatter"
          [editable]="false"
          (focus)="assigneeFocus$.next($any($event).target.value)"
          (click)="assigneeClick$.next($any($event).target.value)"
          #assigneeInstance="ngbTypeahead"
        />
        <span
          *ngIf="
            lookingForProjectForm.get('assignee')?.hasError('required') &&
            lookingForProjectForm.get('assignee')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="freeFrom">
        {{ "home.looking_for_project.create_modal.free_from" | translate }}
      </label>
      <div class="wrapper">
        <input id="freeFrom" formControlName="freeFrom" type="date" />
        <span
          *ngIf="
            lookingForProjectForm.get('freeFrom')?.hasError('required') &&
            lookingForProjectForm.get('freeFrom')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
  </form>
</div>
<div *ngIf="error" class="error">
  <ngb-alert class="alert" type="danger" (closed)="closeAlert()">
    {{ errorMessage }}
  </ngb-alert>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary secondary-btn"
    (click)="activeModal.close(false)"
  >
    {{ "home.looking_for_project.create_modal.cancel" | translate }}
  </button>
  <button type="button" class="btn primary-btn" (click)="saveNewEmployee()">
    {{ "home.looking_for_project.create_modal.save" | translate }}
  </button>
</div>
