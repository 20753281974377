<td>
  <label class="w-100 text-overflow-dotted">
    {{ lookingForProject.employeeName }}
  </label>
</td>
<td>
  <select
    id="assigneeSelect"
    [(ngModel)]="editLookingForProject.assigneeId"
    (change)="saveLookingForProject()"
    (click)="inputClicked($event)"
  >
    <option *ngFor="let user of users" [value]="user.id">
      {{ user.name }}
    </option>
  </select>
</td>
<td>
  <select
    id="statusSelect"
    [(ngModel)]="editLookingForProject.status"
    (change)="saveLookingForProject()"
    (click)="inputClicked($event)"
  >
    <option value="WAITING">
      {{ "home.looking_for_project.status.waiting" | translate }}
    </option>
    <option value="IN_PROGRESS">
      {{ "home.looking_for_project.status.in_progress" | translate }}
    </option>
    <option value="DONE">
      {{ "home.looking_for_project.status.done" | translate }}
    </option>
  </select>
</td>
<td class="text-center">
  <fa-icon
    *ngIf="lookingForProject.comment && lookingForProject.comment !== ''"
    class="comment-icon"
    (click)="openCommentEditModal($event)"
    [icon]="['fas', 'comment']"
  ></fa-icon>
  <fa-icon
    *ngIf="!lookingForProject.comment || lookingForProject.comment === ''"
    class="comment-icon"
    (click)="openCommentEditModal($event)"
    [icon]="['far', 'comment']"
  ></fa-icon>
</td>
<td (dblclick)="setEditStateTrue('freeFrom')" class="text-center">
  <input
    *ngIf="this.editedCell === 'freeFrom'"
    type="date"
    appPrefixFocusAndSelect
    (keydown.enter)="saveLookingForProject()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="saveLookingForProject()"
    [(ngModel)]="editLookingForProject.freeFrom"
    (click)="inputClicked($event)"
  />
  <label *ngIf="this.editedCell !== 'freeFrom'" class="text-overflow-dotted">{{
    lookingForProject.freeFrom
  }}</label>
</td>
<td>
  <label *ngIf="!lookingForProject.seniority">---</label>
  <label *ngIf="lookingForProject.seniority" class="text-overflow-dotted">{{
    "home.looking_for_project.seniority." + lookingForProject.seniority
      | translate
  }}</label>
</td>
<td>
  <label class="w-100 text-overflow-dotted">{{
    lookingForProject.positionName
  }}</label>
</td>
<td class="text-center">
  <fa-icon
    class="cv-icon"
    [icon]="['fas', 'file']"
    (click)="openReadOnlyProfile(lookingForProject.employeeId)"
  ></fa-icon>
</td>
<td class="text-center competency">
  <div
    (click)="selectCompetencyCell($event)"
    [ngClass]="{
      selection: selectedCellEmployeeId === lookingForProject.employeeId,
      'search-find': isSearchResult()
    }"
    class="competency-number"
  >
    {{ averageOfTop3Competencies }}
  </div>
</td>
