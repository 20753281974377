<div
  class="d-flex flex-column h-100 w-100 align-items-center justify-content-start main-container"
>
  <div class="mt-2 d-flex flex-column align-items-center content">
    <div class="box">
      <input
        type="file"
        name="file"
        id="file"
        class="inputfile"
        (change)="onFileSelected($event)"
      />
      <label for="file"
        ><figure>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="17"
            viewBox="0 0 20 17"
          >
            <path
              d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
            />
          </svg>
        </figure>
        <span class="file-input-text" *ngIf="!selectedFile">{{
          "settings.competency_import.upload" | translate
        }}</span>
        <span class="file-input-text" *ngIf="selectedFile">{{
          selectedFile.name
        }}</span></label
      >
    </div>
    <select class="form-select" [(ngModel)]="selectedCompanyId">
      <option *ngFor="let company of companies" [value]="company.id">
        {{ company.name }}
      </option>
    </select>
    <button
      class="btn btn-primary upload-button"
      (click)="uploadFile()"
      [disabled]="!selectedFile"
    >
      {{ "settings.competency_import.import" | translate }}
    </button>
    <div *ngIf="errorWhileImport" class="mt-3">
      <ngb-alert
        class="alert"
        type="danger"
        [dismissible]="false"
        (closed)="errorWhileImport = false"
        >{{ "settings.competency_import.import_fail" | translate }}</ngb-alert
      >
    </div>
    <div *ngIf="successfulImport" class="mt-3">
      <ngb-alert
        class="alert"
        type="success"
        [dismissible]="false"
        (closed)="successfulImport = false"
        >{{
          "settings.competency_import.import_success" | translate
        }}</ngb-alert
      >
    </div>
    <div *ngIf="importInProgress" class="mt-3">
      <ngb-alert
        class="alert"
        type="warning"
        [dismissible]="false"
        (closed)="importInProgress = false"
        >{{
          "settings.competency_import.import_running" | translate
        }}</ngb-alert
      >
    </div>
    <div
      class="latest-import-stats w-100 mt-3 d-flex flex-column"
      *ngIf="this.lastImportStats"
    >
      <h4 class="previous-stat-title w-100">
        {{ "settings.competency_import.latest_import_stats.title" | translate }}
      </h4>
      <div class="w-100 d-flex flex-row justify-content-between">
        <label class="stat-section-title">{{
          "settings.competency_import.latest_import_stats.status" | translate
        }}</label>
        <label
          [ngClass]="{
            'success-status':
              lastImportStats.importStatus === importStatusEnum.Success,
            'failure-status':
              lastImportStats.importStatus === importStatusEnum.Failure
          }"
          *ngIf="lastImportStats.importStatus"
          class="text-overflow-dotted"
          >{{
            "settings.competency_import.latest_import_stats." +
              lastImportStats.importStatus | translate
          }}</label
        >
      </div>
      <div class="w-100 d-flex flex-row justify-content-between">
        <label class="stat-section-title">{{
          "settings.competency_import.latest_import_stats.file_name" | translate
        }}</label>
        <label class="text-overflow-dotted">{{
          lastImportStats.fileName
        }}</label>
      </div>
      <div class="w-100 d-flex flex-row justify-content-between">
        <label class="stat-section-title">{{
          "settings.competency_import.latest_import_stats.nr_of_valid_rows"
            | translate
        }}</label>
        <label>{{ lastImportStats.validRowsCount }}</label>
      </div>
      <div class="w-100 d-flex flex-row justify-content-between">
        <label class="stat-section-title">{{
          "settings.competency_import.latest_import_stats.nr_of_projects"
            | translate
        }}</label>
        <label>{{ lastImportStats.createdProjectsCount }}</label>
      </div>
      <div class="w-100 d-flex flex-row justify-content-between">
        <label class="stat-section-title">{{
          "settings.competency_import.latest_import_stats.nr_of_competencies"
            | translate
        }}</label>
        <label>{{ lastImportStats.createdCompetenciesCount }}</label>
      </div>
      <div class="w-100 d-flex flex-row justify-content-between">
        <label class="stat-section-title">{{
          "settings.competency_import.latest_import_stats.nr_of_plans"
            | translate
        }}</label>
        <label>{{ lastImportStats.createdPlansCount }}</label>
      </div>
      <div class="w-100 d-flex flex-row justify-content-between">
        <label class="stat-section-title">{{
          "settings.competency_import.latest_import_stats.time_of_import"
            | translate
        }}</label>
        <label>{{
          getFormattedTimeOfImport(lastImportStats.runDateTime)
        }}</label>
      </div>
    </div>
  </div>
</div>
