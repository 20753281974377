<div class="modal-header header">
  <label class="title-label">{{
    "employee.projects_modal.title" | translate
  }}</label>
  <label class="name-label text-overflow-dotted">{{ employeeName }}</label>
</div>
<div class="main-container modal-body">
  <div class="project-container" *ngIf="projects.length !== 0">
    <div ngbAccordion class="accordion-flush pb-3" [closeOthers]="true">
      <div ngbAccordionItem *ngFor="let project of projects">
        <h2 ngbAccordionHeader class="w-100">
          <button ngbAccordionButton class="py-1 w-100">
            <span class="project-name pe-1 py-1"
              ><b>{{ project.projectName }}</b>
            </span>
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="d-flex flex-column">
                <ul>
                  <li *ngFor="let task of project.tasks">
                    {{ task }}
                  </li>
                </ul>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="empty-project-container" *ngIf="projects.length === 0">
    <label class="text-center">
      {{ "employee.projects_modal.empty_projects" | translate }}
    </label>
  </div>
</div>
<div class="modal-actions modal-footer">
  <button
    type="button"
    class="btn btn-primary close-button"
    (click)="activeModal.close()"
  >
    {{ "employee.projects_modal.close" | translate }}
  </button>
</div>
