<div class="d-flex w-100 h-100 base-container">
  <div class="content mw-100 mh-100 d-flex flex-grow-1 pt-3 flex-column">
    <div class="title-container">
      <img
        src="..\..\assets\img\squirrel_standing_tie.png"
        class="title-squirrel-image"
      />
      <h1 class="title">{{ "employee.title" | translate }}</h1>
    </div>
    <app-company-separated-display
      [companies]="this.visibleCompanies"
      class="flex-grow-1 d-flex flex-column pb-3 tabs"
      (companyAddedEvent)="refreshCompanies()"
    >
      <ng-template companyMarker *ngFor="let company of visibleCompanies">
        <app-table-employee
          [company]="company"
          class="employee-table h-100"
        ></app-table-employee>
      </ng-template>
    </app-company-separated-display>
  </div>
  <div class="competency">
    <app-side-toolbar [title]="'competency.competencies' | translate">
      <app-competency>
        <app-employee-competency class="competencies"></app-employee-competency>
      </app-competency>
    </app-side-toolbar>
  </div>
</div>
