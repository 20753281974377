<div class="modal-header">
  <h4 class="modal-title">
    {{ "basket.create_basket_modal.create_basket" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.close(false)"
  ></button>
</div>
<div class="modal-body">
  <form class="form">
    <div class="form-row">
      <label for="basketName">{{ "basket.create_basket_modal.basket_name" | translate }}</label>
      <div class="wrapper">
        <input
          id="basketName"
          type="text"
          [formControl]="basketNameForm"
          (blur)="validateName()"
        />
        <span
          *ngIf="
            basketNameForm?.hasError('required') && basketNameForm?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary secondary-btn"
    (click)="activeModal.close(false)"
  >
    {{ "basket.create_basket_modal.cancel" | translate }}
  </button>
  <button
    type="button"
    class="btn primary-btn"
    (click)="saveCompetency()"
  >
    {{ "basket.create_basket_modal.save" | translate }}
  </button>
</div>
