import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  Observable,
  OperatorFunction,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
} from "rxjs";
import {
  LookingForResourceService,
  ProjectSelectDTO,
  ProjectService,
  UserSelectDTO,
} from "src/shared/api/generated";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-create-looking-for-resource",
  templateUrl: "./create-looking-for-resource.component.html",
  styleUrls: ["./create-looking-for-resource.component.scss"],
})
export class CreateLookingForResourceComponent implements OnInit {
  @Input() users: UserSelectDTO[] = [];

  lookingForResourceForm: FormGroup = new FormGroup({
    project: new FormControl<ProjectSelectDTO | null>({ value: null, disabled: true }, Validators.required),
    assignee: new FormControl<UserSelectDTO | null>(null, Validators.required),
    requestStart: new FormControl<string | undefined>(undefined),
    requestEnd: new FormControl<string | undefined>(undefined),
    fte: new FormControl<number>(0, Validators.required)
  });

  projects: ProjectSelectDTO[] = [];

  error: boolean = false;
  errorMessage: string = "";

  @ViewChild("projectInstance", { static: true })
  projectInstance: NgbTypeahead = {} as NgbTypeahead;
  @ViewChild("assigneeInstance", { static: true })
  assigneeInstance: NgbTypeahead = {} as NgbTypeahead;

  projectFocus$ = new Subject<string>();
  assigneeFocus$ = new Subject<string>();
  projectClick$ = new Subject<string>();
  assigneeClick$ = new Subject<string>();

  constructor(
    public activeModal: NgbActiveModal,
    private lookingForResourceService: LookingForResourceService,
    private translate: TranslateService,
    private projectService: ProjectService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getProjects();
  }

  getProjects() {
    this.projectService.getAllProjectSelectDtos()
    .pipe(showLoadingSpinner(this.spinnerService)).subscribe((allProjects) => {
      this.projects = allProjects;
      this.lookingForResourceForm.get('project')?.enable();
    });
  }

  saveNewProject() {
    if (this.isDisabled()) {
      this.lookingForResourceForm.markAllAsTouched();
      return;
    }
    const { requestStart, requestEnd, fte} = this.lookingForResourceForm.value;
    this.lookingForResourceService
      .createLookingForResource({
        status: "WAITING",
        projectId: this.project.id!,
        assigneeId: this.assignee.id!,
        requestStart,
        requestEnd,
        fte
      })
      .subscribe({
        next: (_) => {
          this.activeModal.close(true);
        },
        error: (error) => {
          if (
            error.error.message ===
            "There is already an active Looking For Resource for the given project"
          )
            this.errorMessage = this.translate.instant(
              "home.looking_for_resources.create_modal.error.already_has_active_entry"
            );
          this.error = true;
        },
      });
  }

  isDisabled(): boolean {
    return (
      !this.project || this.project.id < 0 ||
      !this.assignee || this.assignee.id < 0 ||
      this.fte === null
    );
  }

  closeAlert() {
    this.error = false;
  }

  searchProject: OperatorFunction<string, ProjectSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.projectClick$.pipe(
      filter(() => !this.projectInstance.isPopupOpen())
    );
    const inputFocus$ = this.projectFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? []
          : this.projects.filter((v) => new RegExp(term, "miu").test(v.name!))
        ).slice(0, 10)
      )
    );
  };

  searchAssignee: OperatorFunction<string, UserSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.assigneeClick$.pipe(
      filter(() => !this.assigneeInstance.isPopupOpen())
    );
    const inputFocus$ = this.assigneeFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? this.users
          : this.users.filter((v) => new RegExp(term, "miu").test(v.name!))
        )
          .sort((a, b) => a.name!.localeCompare(b.name!))
          .slice(0, 10)
      )
    );
  };

  formatter = (x: ProjectSelectDTO | UserSelectDTO) => x.name!;

  get project() {
    return this.lookingForResourceForm.get("project")?.value;
  }

  get assignee() {
    return this.lookingForResourceForm.get("assignee")?.value;
  }

  get fte() {
    return this.lookingForResourceForm.get("fte")?.value;
  }
}
