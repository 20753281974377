import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CompetencyGroupDTO, CompetencyService, CreateOrUpdateCompetencyDTO } from "src/shared/api/generated";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";

@Component({
  selector: "app-add-or-edit-competency-modal",
  templateUrl: "./add-or-edit-competency-modal.component.html",
  styleUrls: ["./add-or-edit-competency-modal.component.scss"],
})
export class AddOrEditCompetencyModalComponent implements OnInit {
  @Input() competency!: CreateOrUpdateCompetencyDTO;
  @Input() competencyGroups: CompetencyGroupDTO[] = [];
  @Input() createMode: boolean = false;

  competencyForm!: FormGroup;

  error: boolean = false;
  errorMessage: string = "";

  constructor(
    public activeModal: NgbActiveModal,
    private competencyService: CompetencyService,
    private translate: TranslateService,
    private competencyManagerService: CompetencyManagerService
  ) {}

  ngOnInit(): void {
    this.competencyForm = new FormGroup({
      id: new FormControl<number | undefined>(this.competency.id),
      name: new FormControl<string>(this.competency.name, Validators.required),
      competencyGroupId: new FormControl<number>(this.competency.competencyGroupId, Validators.required)
    });
  }

  saveCompetency() {
    if (this.competencyForm.invalid) {
      this.competencyForm.markAllAsTouched();
      return;
    }
    const competencyToSave: CreateOrUpdateCompetencyDTO = this.competencyForm.value;
    const competencyObservable = this.createMode
      ? this.competencyService.createCompetency(competencyToSave)
      : this.competencyService.updateCompetency(competencyToSave);

    competencyObservable.subscribe({
      next: (competency) => {
        this.competencyManagerService.setCompetencyCreated();
        this.activeModal.close(competency);
      },
      error: (error) => {
        this.openAlert(error.error.message);
      }
    });
  }

  openAlert(error: string) {
    if(error === "Competency already exists with the name"){
      this.error = true;
      this.errorMessage = this.translate.instant(
        "competency.competency_add_modal.already_exists"
      );
    }
  }

  closeAlert() {
    this.error = false;
    this.errorMessage = "";
  }
}
