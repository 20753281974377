<div class="main-container">
  <label class="title-label">
    {{ "employee.education_modal.title" | translate }}
  </label>
  <label class="sub-title text-overflow-dotted">{{ employeeName }}</label>
  <div class="content">
    <app-table-education
      [employeeId]="employeeId"
      (educationSaved)="onEducationSaved()"
      (educationSaveFailed)="onEducationSaveFailed($event)"
    ></app-table-education>
  </div>
  <div *ngIf="error" class="error">
    <ngb-alert class="alert" type="danger" (closed)="closeAlert()">
      {{ errorMessage }}
    </ngb-alert>
  </div>
  <div class="modal-actions">
    <button
      type="button"
      class="btn secondary-btn"
      (click)="activeModal.close(educationModified)"
    >
      {{ "employee.education_modal.close" | translate }}
    </button>
  </div>
</div>
