<div class="main-container">
  <div *ngIf="editedEmployeeCompetency.employeeName" class="labels">
    <div class="text-overflow-dotted">
      {{ editedEmployeeCompetency.employeeName }}
    </div>
  </div>
  <div class="search-bar pt-3 px-2">
    <input
      type="search"
      class="form-control"
      placeholder="{{ 'competency.search_competency' | translate }}"
      #searchInput
      data-testid="competency-search-input"
      [(ngModel)]="searchText"
      (input)="onSearchInput()"
    />
    <button
      *ngIf="!editedEmployeeCompetency.displayOnly && isAdmin"
      class="btn primary-btn ms-2"
      (click)="onAddCompetency(searchInput.value)"
      [disabled]="isAddButtonDisabled()"
    >
      {{ "competency.add_competency" | translate }}
    </button>
  </div>
  <ng-container *ngIf="!errorFetchingCompetencies; else errorMessage">
    <div class="competency-container">
      <div *ngIf="!editEmployeeCompetenciesMode" class="full-height py-2 px-2">
        <div class="full-height competency-list scrollable-list">
          <div
            ngbAccordion
            #basicCompetencyAccordion="ngbAccordion"
            class="w-100"
          >
            <div
              ngbAccordionItem="{{ type.name }}"
              *ngFor="let type of competencyGroups"
            >
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton>
                  <div class="category-header">
                    <div>
                      {{ type.name }}
                    </div>
                    <div class="ms-auto me-4">
                      {{
                        getCompetenciesInCategoryFromArray(
                          filteredCompetencies,
                          type.id
                        ).length
                      }}
                    </div>
                  </div>
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div>
                      <div
                        class="competency-item"
                        *ngFor="
                          let competency of getCompetenciesInCategoryFromArray(
                            filteredCompetencies,
                            type.id
                          )
                        "
                      >
                        <div class="d-flex flex-row align-items-center w-100">
                          <span
                            class="text-overflow-dotted competency-name"
                            (dblclick)="onEditCompetency(competency)"
                            >{{ competency.name }}</span
                          >
                          <fa-icon
                            *ngIf="
                              !editedEmployeeCompetency.displayOnly && isAdmin
                            "
                            class="ms-auto remove-icon"
                            [icon]="['fas', 'trash']"
                            (click)="onDeleteCompetency(competency.id!)"
                          ></fa-icon>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="editEmployeeCompetenciesMode" class="full-height py-2 px-2">
        <div
          [ngClass]="
            editedEmployeeCompetency.displayOnly ? 'full-height' : 'half-height'
          "
          class="competency-list scrollable-list"
        >
          <div
            id="employee-competency-accordion"
            ngbAccordion
            #employeeCompetencyAccordion="ngbAccordion"
            class="w-100"
          >
            <div
              ngbAccordionItem="{{ type.name }}"
              *ngFor="let type of competencyGroups"
            >
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton>
                  <div class="category-header">
                    <div>
                      {{ type.name }}
                    </div>
                    <span
                      *ngIf="type.name == 'Language'"
                      ngbTooltip="{{
                        'competency.language_level_tooltip' | translate
                      }}"
                      placement="bottom"
                      container="body"
                      class="ms-1"
                      ><fa-icon [icon]="['fas', 'circle-info']"></fa-icon
                    ></span>
                    <div class="ms-auto me-4">
                      {{
                        getCompetenciesInCategoryFromArray(
                          getFilteredCompetencies(),
                          type.id
                        ).length
                      }}
                    </div>
                  </div>
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div>
                      <div
                        class="competency-item"
                        *ngFor="
                          let competency of getCompetenciesInCategoryFromArray(
                            getFilteredCompetencies(),
                            type.id
                          )
                        "
                      >
                        <fa-icon
                          *ngIf="
                            !editedEmployeeCompetency.displayOnly &&
                            competency.id !== levelEditedEmployeeCompetency?.id
                          "
                          class="add-remove-icon"
                          [icon]="['fas', 'minus']"
                          (click)="deleteEditedCompetency(competency)"
                        ></fa-icon>
                        <div class="d-flex flex-row align-items-center w-100">
                          <span
                            class="text-overflow-dotted competency-name"
                            (dblclick)="onEditCompetency(competency)"
                            >{{ competency.name }}</span
                          >
                          <ng-container
                            *ngIf="
                              competency.id !==
                                levelEditedEmployeeCompetency?.id;
                              else levelEditMode
                            "
                          >
                            <span class="ms-auto competency-level">
                              <label>
                                {{ getCompetencyLevel(competency.id!) }}
                              </label>
                              <fa-icon
                                *ngIf="!editedEmployeeCompetency.displayOnly"
                                (click)="onStartEditingLevel(competency)"
                                class="edit-level-icon"
                                [icon]="['fas', 'pen']"
                              ></fa-icon>
                            </span>
                          </ng-container>
                          <ng-template #levelEditMode>
                            <span class="ms-auto competency-level">
                              <input
                                class="level-input-box"
                                type="number"
                                min="1"
                                max="5"
                                step="1"
                                [(ngModel)]="
                                  levelEditedEmployeeCompetency!.level
                                "
                                (keydown.enter)="onSaveLevelEditing()"
                                (keydown.escape)="onCancelLevelEditing()"
                                (click)="$event.stopPropagation()"
                              />
                              <fa-icon
                                class="save-edit-icon mx-2"
                                [icon]="['fas', 'check']"
                                (click)="onSaveLevelEditing()"
                              ></fa-icon>
                              <fa-icon
                                class="cancel-edit-icon"
                                [icon]="['fas', 'xmark']"
                                (click)="onCancelLevelEditing()"
                              ></fa-icon>
                            </span>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr *ngIf="!editedEmployeeCompetency.displayOnly" class="divider" />
        <div
          *ngIf="!editedEmployeeCompetency.displayOnly"
          class="half-height competency-list scrollable-list"
        >
          <div
            id="non-employee-competency-accordion"
            ngbAccordion
            #nonEmployeeCompetencyAccordion="ngbAccordion"
            class="w-100"
          >
            <div
              ngbAccordionItem="{{ type.name }}"
              *ngFor="let type of competencyGroups"
            >
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton>
                  <div class="category-header">
                    <div>
                      {{ type.name }}
                    </div>
                    <div class="ms-auto me-4">
                      {{
                        getCompetenciesInCategoryFromArray(
                          filteredNonEmployeeCompetencies,
                          type.id
                        ).length
                      }}
                    </div>
                  </div>
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div>
                      <div
                        class="competency-item"
                        *ngFor="
                          let competency of getCompetenciesInCategoryFromArray(
                            filteredNonEmployeeCompetencies,
                            type.id
                          )
                        "
                      >
                        <fa-icon
                          class="add-remove-icon"
                          [icon]="['fas', 'plus']"
                          (click)="createEditedCompetency(competency)"
                        ></fa-icon>
                        <div class="d-flex flex-row align-items-center w-100">
                          <span
                            class="text-overflow-dotted competency-name"
                            (dblclick)="onEditCompetency(competency)"
                            >{{ competency.name }}</span
                          >
                          <fa-icon
                            *ngIf="isAdmin"
                            class="ms-auto remove-icon"
                            [icon]="['fas', 'trash']"
                            (click)="onDeleteCompetency(competency.id!)"
                          ></fa-icon>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #errorMessage>
    <div class="error-message pt-5 px-3">
      {{ "competency.errorFetchingCompetencies" | translate }}
    </div>
  </ng-template>
</div>
