import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import {
  EducationDTO,
  CompetencyGroupDTO,
  CompetencyGroupService,
  EmployeeDTO,
  ExternalProjectDTO,
  ProfileCompetencyDTO,
  ProfileDTO,
  ProfileEducationDTO,
  ProfileProjectDTO,
  ProfileService,
  ProfileProjectCompetencyDTO,
} from "src/shared/api/generated";
import { CompetencyPillCommand } from "src/shared/models/competency-pill-command.model";

@Component({
  selector: "app-modify-profile-modal",
  templateUrl: "./modify-profile-modal.component.html",
  styleUrls: ["./modify-profile-modal.component.scss"],
})
export class ModifyProfileModalComponent implements OnInit {
  @Input() profile: ProfileDTO = {} as ProfileDTO;

  editedExternalProject: ExternalProjectDTO = {} as ExternalProjectDTO;
  editExternalProjectMode: boolean = false;
  editedExternalProjectIndex: number | null = null;
  addExternalProjectMode: boolean = false;

  editedStudy: string = "";
  editedStudyIndex: number | null = null;
  editStudiesMode: boolean = false;
  addStudyMode: boolean = false;

  seniorities = EmployeeDTO.SeniorityEnum;

  currentDate: Date = new Date();

  competencyDisplayModes = Object.values(
    ProfileCompetencyDTO.ShowCompetencyAsEnum
  );

  technologicalCompetencyGroup: CompetencyGroupDTO = {} as CompetencyGroupDTO;
  languageCompetencyGroup: CompetencyGroupDTO = {} as CompetencyGroupDTO;

  allProfileCompetencies: ProfileCompetencyDTO[] = [];
  minimalShownLevel: number = 1;

  changeProjectCompetencyVisibility = (
    competency: ProfileProjectCompetencyDTO
  ) => {
    competency.visible = !competency.visible;
  };
  isProjectCompetencyActive = (competency: ProfileProjectCompetencyDTO) => {
    return !competency.visible;
  };
  commands: CompetencyPillCommand[] = [
    {
      icon: "eye-slash",
      function: this.changeProjectCompetencyVisibility,
      class: ["pill-dnd"],
      alt: {
        isAltActive: this.isProjectCompetencyActive,
        icon: "eye",
        function: this.changeProjectCompetencyVisibility,
        class: ["pill-dnd", "pill-unselected"],
      },
    },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private competencyGroupService: CompetencyGroupService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.competencyGroupService.getAllCompetencyGroups().subscribe({
      next: (competencyGroups) => {
        this.technologicalCompetencyGroup = competencyGroups.find(
          (cg) => cg.name === "Technological"
        )!;
        this.languageCompetencyGroup = competencyGroups.find(
          (cg) => cg.name === "Language"
        )!;
      },
    });
    this.currentDate = new Date(moment.now());
  }

  changeProjectVisibility(project: ProfileProjectDTO) {
    project.visible = !project.visible;
  }

  changeShowSeniority() {
    this.profile.showSeniority = !this.profile.showSeniority;
  }

  removeExternalProject(project: ExternalProjectDTO) {
    if (this.editExternalProjectMode) {
      return;
    }
    this.profile.externalProjects?.splice(
      this.profile.externalProjects?.indexOf(project),
      1
    );
  }

  startEditingExternalProject(index: number) {
    if (this.editExternalProjectMode) {
      return;
    }
    this.editExternalProjectMode = true;
    this.editedExternalProject = { ...this.profile.externalProjects![index] };
    this.editedExternalProjectIndex = index;
  }

  saveProjectEdit() {
    if (
      this.editedExternalProject.projectName === "" ||
      this.editedExternalProject.year === ""
    ) {
      return;
    }

    if (this.editedExternalProjectIndex != null) {
      this.profile.externalProjects![this.editedExternalProjectIndex] =
        this.editedExternalProject;
    }
    this.editedExternalProject = {} as ExternalProjectDTO;
    this.editExternalProjectMode = false;
    this.editedExternalProjectIndex = null;
    this.addExternalProjectMode = false;
  }

  cancelProjectEdit() {
    if (this.addExternalProjectMode) {
      this.profile.externalProjects?.pop();
    }
    this.editedExternalProject = {} as ExternalProjectDTO;
    this.editExternalProjectMode = false;
    this.editedExternalProjectIndex = null;
    this.addExternalProjectMode = false;
  }

  addExternalProject() {
    if (this.editExternalProjectMode) {
      return;
    }

    this.editedExternalProjectIndex =
      this.profile.externalProjects!.push({
        id: undefined,
        year: "",
        projectName: "",
        task: undefined,
      }) - 1;
    this.editExternalProjectMode = true;
    this.addExternalProjectMode = true;
    this.editedExternalProject = {
      ...this.profile.externalProjects![this.editedExternalProjectIndex],
    };
  }

  getTasksByInternalProject(internalProject: ProfileProjectDTO) {
    return internalProject.project?.tasks?.filter((task) => task !== "");
  }

  getTechnologicalCompetencies(): ProfileCompetencyDTO[] {
    return (
      this.profile.competencies?.filter(
        (c) =>
          c.competency?.competencyGroup.rootGroupId ===
          this.technologicalCompetencyGroup.id
      ) ?? []
    );
  }

  getLanguageCompetencies() {
    return (
      this.profile.competencies?.filter(
        (c) =>
          c.competency?.competencyGroup.rootGroupId ===
          this.languageCompetencyGroup.id
      ) ?? []
    );
  }

  changeCompetencyVisibility(competency: ProfileCompetencyDTO) {
    competency.visible = !competency.visible;
  }

  hideCompetency(competency: ProfileCompetencyDTO) {
    competency.visible = false;
  }

  changeTechnologicalCompetencyDisplayMode(event: any) {
    this.getTechnologicalCompetencies().forEach(
      (c) => (c.showCompetencyAs = event.target.value)
    );
  }

  changeLanguageCompetencyDisplayMode(event: any) {
    this.getLanguageCompetencies().forEach(
      (c) => (c.showCompetencyAs = event.target.value)
    );
  }

  changeEducationVisibility(education: ProfileEducationDTO) {
    education.visible = !education.visible;
  }

  saveProfile() {
    if (this.editExternalProjectMode) {
      return;
    }
    this.profile.internalProjects?.forEach((project) => {
      project.profileProjectCompetencyOrder = project?.project
        ?.profileProjectCompetencies
        ? project.project.profileProjectCompetencies.map(
            (competency) => competency.id!
          )
        : [];
    });
    this.profileService
      .updateProfile(this.profile)
      .subscribe({ next: (profile) => this.activeModal.close(profile) });
  }

  isEducationExpired(educationDTO: EducationDTO) {
    if (!educationDTO.validUntil) {
      return false;
    }
    return new Date(educationDTO.validUntil) < this.currentDate;
  }

  onMinimalShownLevelSave() {
    this.allProfileCompetencies = this.getTechnologicalCompetencies().concat(
      this.getLanguageCompetencies()
    );
    this.allProfileCompetencies
      .forEach((c) => {
        if (c.level && c.level < this.minimalShownLevel) this.hideCompetency(c);
      });
  }
}
