<div class="modal-header">
  <div>
    <h4 class="modal-title">
      {{ "plan.create_modal.title" | translate }}
    </h4>
    <div class="label-container">
      <label class="sub-title">{{ employeeName }}</label>
    </div>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.close(false)"
  ></button>
</div>
<div class="modal-body">
  <form class="form" [formGroup]="planForm">
    <div class="form-row">
      <label for="project">{{ "plan.create_modal.project" | translate }}</label>
      <label
        *ngIf="selectedProject && selectedProject.id! >= 0"
        class="date-label"
        >{{ selectedProject.start | date }} -
        {{ selectedProject.end | date }}
      </label>
      <ng-template #resultTemplate let-r="result" let-t="term">
        <ngb-highlight
          class="typeahead-highlight me-1"
          [result]="r.name"
          [term]="t"
        ></ngb-highlight>
        <label>({{ "project.state." + r.state | translate }})</label>
      </ng-template>
      <div class="wrapper">
        <input
          id="project"
          type="text"
          formControlName="selectedProject"
          [ngbTypeahead]="searchProject"
          [resultTemplate]="resultTemplate"
          [inputFormatter]="inputFormatter"
          [editable]="false"
          (focus)="projectFocus$.next($any($event).target.value)"
          (click)="projectFocus$.next($any($event).target.value)"
          (selectItem)="fetchCompetencies($event)"
          #employeeInstance="ngbTypeahead"
        />
        <span
          *ngIf="
            planForm.get('selectedProject')?.hasError('required') &&
            planForm.get('selectedProject')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="start">{{ "plan.create_modal.start" | translate }}</label>
      <div class="wrapper">
        <input formControlName="from" id="start" type="date" />
        <span
          *ngIf="
            planForm.get('from')?.hasError('required') &&
            planForm.get('from')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="end">{{ "plan.create_modal.end" | translate }}</label>
      <div class="wrapper">
        <input formControlName="to" id="end" type="date" />
        <span
          *ngIf="
            planForm.get('to')?.hasError('required') &&
            planForm.get('to')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
        <span *ngIf="planForm.hasError('invalidDate')" class="form-error">
          {{ "plan.create_modal.error.to_earlier_than_from" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="percentage">
        {{ "plan.create_modal.percentage" | translate }}
      </label>
      <div class="wrapper">
        <input
          type="range"
          min="0"
          max="100"
          step="5"
          formControlName="percentage"
          [value]="planForm.value.percentage"
        />
        <input
          id="percentage"
          type="number"
          min="0"
          max="100"
          class="percentage-number-input"
          formControlName="percentage"
          [value]="planForm.value.percentage"
          (blur)="validatePercentageField()"
        />
        <span
          *ngIf="
            planForm.get('percentage')?.hasError('min') &&
            planForm.get('percentage')?.touched
          "
          class="form-error"
        >
          {{ "form.error.percentage_min_value" | translate }}
        </span>
        <span
          *ngIf="
            planForm.get('percentage')?.hasError('required') &&
            planForm.get('percentage')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="description">
        {{ "plan.create_modal.description" | translate }}
      </label>
      <div class="w-50">
        <textarea
          id="description"
          class="px-2"
          rows="4"
          placeholder="{{ 'plan.create_modal.placeholder' | translate }}"
          formControlName="description"
        ></textarea>
      </div>
    </div>
    <div class="form-row">
      <label>{{ "plan.create_modal.competency" | translate }}</label>
      <div class="w-50">
        <app-competency-pill-container
          [competencies]="competencies"
          [allCompetencies]="allCompetencies"
          [employeeCompetencies]="employeeCompetencies"
          [commands]="commands"
          [isAddDisabled]="!selectedProject"
          [isAddPillButtonActive]="true"
          (newCompetencyEvent)="checkAndAddNewCompetency($event)"
        ></app-competency-pill-container>
      </div>
    </div>
  </form>
</div>
<div *ngIf="error" class="error">
  <ngb-alert class="alert" type="danger" (closed)="closeAlert()">
    {{ errorMessage }}
  </ngb-alert>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary secondary-btn"
    (click)="activeModal.close(false)"
  >
    {{ "plan.create_modal.cancel" | translate }}
  </button>
  <button
    type="button"
    class="btn primary-btn"
    [disabled]="isLoading"
    (click)="saveNewPlan()"
  >
    {{ "plan.create_modal.save" | translate }}
  </button>
</div>
